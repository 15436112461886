@import "../constatnt/fonts.scss";
@import "../constatnt/flex.scss";
@import "../constatnt/color.scss";
@import "../constatnt/border.scss";
@import "../template/color.scss";

body,
html {
  font-family: "f1";
  margin: 0;
  padding: 0;
  // overflow-x: hidden;
}
body {
  background-color: $lt-blue2 !important;
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(230, 230, 230);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
}

.out-left {
  z-index: 0;
}
.top-header {
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  .sign-btn {
    width: 125px;
    height: 50px;
    border-radius: 13px;
    align-items: center;
    background: $lt-blue1;
    justify-content: space-between;
    display: flex;
    padding: 0 30px;
    svg {
      width: 32px;
      height: 32px;
    }
    .user-icon {
      width: 32px;
      height: 32px;
      margin-left: 10px;
    }
  }
  .sign-drop-list {
    position: absolute;
    right: 0px;
    top: 44px;
    background: #f1f7ff;
    border: 3px solid #d7dff1;
    box-shadow: 6px 6px 25px -13px #7787ac;
    -webkit-box-shadow: 6px 6px 25px -13px #7787ac;
    padding: 10px 10px;
    border-radius: 20px;
    width: 200px;
    z-index: 1;
    button {
      width: 100%;
      h5 {
        color: $black;
        font-size: 16px;
        font-family: "f3";
      }
    }
  }
  .menu-item {
    a,
    .menu-head-btn {
      color: $black;
      font-family: "f3";
    }
    .active {
      width: 60px;
      height: 3px;
      background: $primary1;
      position: absolute;
      bottom: -5px;
      left: calc(50% - 30px);
      border-radius: 10px;
    }
    .drop-list {
      left: -40px;
      top: 34px;
      background: #f1f7ff;
      border: 3px solid #d7dff1;
      box-shadow: 6px 6px 25px -13px #7787ac;
      -webkit-box-shadow: 6px 6px 25px -13px #7787ac;
      padding: 20px 10px;
      border-radius: 20px;
      width: 230px;
      z-index: 1;
      .left-imgs {
        width: 56px;
        height: 56px;
        border-radius: 7px;
        background: #e2efff;
        margin-right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80%;
          height: auto;
        }
      }
      .dorp-rit-cont {
        width: 150px;
        h5 {
          color: $black;
          font-size: 16px;
          font-family: "f3";
        }
        p {
          color: $primary1;
          font-size: 14px;
          font-family: "f2";
          margin-bottom: 0;
        }
      }
    }
  }
}
.menu-icon {
  width: 40px;
  height: 37px;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  transition: 0.2s all linear;
  background: $lt-blue1;
  border-radius: 7px;
  border: 0;
  svg {
    font-size: 20px;
  }
}

.home-tab {
  .pac-box {
    width: 37%;
    margin-top: 10px;
    padding: 0 3%;
    .pac-box1 {
      border-radius: 30px;
      background: #fff;
      box-shadow:
        inset -2px 2px 4px 3px #fff,
        10px 10px 13px -5px rgba(161, 183, 219, 0.44);
      -webkit-box-shadow:
        inset -2px 2px 4px 3px #fff,
        10px 10px 13px -5px rgba(161, 183, 219, 0.44);
      margin-bottom: 20px;
      padding: 30px 10px;
      .top-img {
        width: 70%;
        margin: auto;
        .img1 {
          width: 100%;
        }
      }
      .logist {
        width: 20px;
        height: 20px;
      }
      h5 {
        font-size: 20px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }
    }
  }
}

// para font
.parag {
  font-family: "f2";
  color: $primary1;
  font-size: 15px;
}

// List Box start
.contain {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.home-support {
  background: #f1f7fc;
  box-shadow:
    inset 5px 7px 10px 5px #fff,
    inset 0px 3px 11px 7px #d8d8e7,
    5px 5px 10px 0px #eef1fd;
  -webkit-box-shadow:
    inset 5px 7px 10px 5px #fff,
    inset 0px 3px 11px 7px #d8d8e7,
    5px 5px 10px 0px #eef1fd;
  border-radius: 15px;
  margin-bottom: 40px;
  padding: 30px 10px;
}
// List Box end

// Menu start
.menu-item {
  a,
  .menu-head-btn {
    color: $black;
    font-family: "f3";
  }
  .active {
    width: 60px;
    height: 3px;
    background: $primary1;
    position: absolute;
    bottom: -5px;
    left: calc(50% - 30px);
    border-radius: 10px;
  }
  .drop-list {
    left: -9px;
    top: 41px;
    background: #f1f7ff;
    border: 3px solid #d7dff1;
    box-shadow: 6px 6px 25px -13px #7787ac;
    -webkit-box-shadow: 6px 6px 25px -13px #7787ac;
    border-radius: 20px;
    z-index: 1;
    width: 190px;

    .left-imgs {
      width: 56px;
      height: 56px;
      border-radius: 7px;
      background: #e2efff;
      margin-right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80%;
        height: auto;
      }
    }
    .dorp-rit-cont {
      width: 150px;
      h5 {
        color: $black;
        font-size: 16px;
        font-family: "f3";
      }
      p {
        color: $primary1;
        font-size: 14px;
        font-family: "f2";
        margin-bottom: 0;
      }
    }
  }
}
// Menu end

// Footer start
.footer {
  background: $lt-blue;
  padding: 20px 5px;
  // z-index: 0;
  // position: relative;
  h3 {
    font-size: 33px;
    font-family: "f4";
  }
  .social-ftr-lnk {
    margin-left: -10px;
  }
  img {
    width: 100px;
  }
  .social-footer-icon {
    background: #fff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    box-shadow:
      inset -1px -2px 5px 3px #cadbf1,
      2px 2px 6px 0px #e2ebff,
      inset 2px 2px 6px 0px #f6f8ff;
    -webkit-box-shadow:
      inset -1px -2px 5px 3px #cadbf1,
      2px 2px 6px 0px #e2ebff,
      inset 2px 2px 6px 0px #f6f8ff;
    border-radius: 5px;
    margin: 10px;
    img {
      width: 30px;
      height: 30px;
      padding: 5px;
    }
  }
  .logos {
    width: 260px;
  }
}
.ftr-list {
  width: 25%;
  margin-bottom: 20px;
  h5 {
    font-size: 23px;
    font-family: "f4";
  }
  a {
    display: inherit;
    font-size: px;
    font-family: "f2";
  }
}
.rit-ftr {
  width: calc(100% - 260px);
}

.ftr-btm {
  border-top: 2px solid $orange;
  padding: 10px;
  width: 80%;
}
// Footer end

// Search start
.hide-cont {
  height: 60px;
  width: 57px;
  background-color: #000 !important;
  position: fixed;
  z-index: -1;
  transition: linear 0.2s;
}

.show-cont {
  height: 60px;
  width: 57px;
  background-color: #000 !important;
  position: absolute;
  z-index: -1;
  right: -100px;
  transition: linear 0.5s;
}

.loginpopup {
  width: 50px;
  height: 100vh;
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.loginpopuplayout .modal-body {
  position: fixed;
  flex-direction: column;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
  top: 0;
  right: 0;
}

.loginpopup1 {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: #00000066;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  right: 0;
  left: 0;
  bottom: 0;
}

.loginpopuplayout1 .modal-body {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgb(255, 255, 255);
  position: relative;
  border-radius: 5px;
  height: 100%;
  width: 95%;
}

.btn-custom {
  background-color: #00000066 !important;
}
.search-bar {
  height: 88px;
  // width: 100%;
  z-index: 100;
  position: fixed;
  top: 0;
  background-color: #eee;
  right: 0;
  -webkit-transition:
    opacity 0.18s,
    width 0.18s,
    height 0.18s step-start,
    visibility 0.18s step-start,
    box-shadow 0.3s ease;
  transition:
    opacity 0.18s,
    width 0.18s,
    height 0.18s step-start,
    visibility 0.18s step-start,
    box-shadow 0.3s ease;
  visibility: visible;
}
.form-cont {
  background-color: #fff;
  border-radius: 4px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  color: #212121;
  height: 48px;
  margin: 0 auto;
  max-width: 720px;
  padding: 0;
  width: 100%;
}

.arrow-cont {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  opacity: 1;
}

.search-cont {
  display: -webkit-box;
  display: -webkit-flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  top: 14px;
}

.form-hide.arrow-cont {
  height: 48px;
  width: 100%;
  -webkit-transition:
    opacity 0.18s,
    width 0.18s,
    height 0.18s step-start,
    visibility 0.18s step-start,
    box-shadow 0.3s ease;
  transition:
    opacity 0.18s,
    width 0.18s,
    height 0.18s step-start,
    visibility 0.18s step-start,
    box-shadow 0.3s ease;
  visibility: visible;
}
.search-icon {
  color: #616161;
  fill: currentColor;
  height: 24px;
  width: 24px;
}

.searchImgIcon {
  height: 20px;
  width: 20px;
  object-fit: cover;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

.input-box {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -webkit-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  background-color: transparent;
  border: none;
  display: block;
  font:
    400 16px Roboto,
    RobotoDraft,
    Helvetica,
    Arial,
    sans-serif;
  height: 24px;
  line-height: 24px;
  margin: 0;
  min-width: 0%;
  outline: none;
  padding: 0;
  z-index: 0;
}

.input-cont {
  display: -webkit-box;
  display: -webkit-flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -webkit-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  min-width: 0%;
  position: relative;
}
.arrow-box {
  -webkit-user-select: none;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  border: 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  -webkit-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  height: 48px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  width: 48px;
  z-index: 0;
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
}
user agent stylesheet div {
  display: block;
}

.form-hide {
  background-color: #eee;
  height: 0;
  opacity: 0;
  padding: 14px 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-transition:
    opacity 0.18s,
    width 0.18s,
    height 0.18s step-start,
    visibility 0.18s step-start,
    box-shadow 0.3s ease;
  transition:
    opacity 0.18s,
    width 0.18s,
    height 0.18s step-start,
    visibility 0.18s step-start,
    box-shadow 0.3s ease;
  visibility: hidden;
  display: none;
  width: 0;
  z-index: 110;
}
// Search End

// Passwod start
.rit-auth {
  .form-box {
    width: 400px;
    padding: 30px 30px;
    border-radius: 15px;
    background: #f3f7ff;
    box-shadow:
      9px 8px 11px -2px #dfe8f8,
      inset 12px 12px 6px -4px #ffffff,
      inset -12px -12px 12px -4px #d7deef;
    -webkit-box-shadow:
      9px 8px 11px -2px #dfe8f8,
      inset 12px 12px 6px -4px #ffffff,
      inset -12px -12px 12px -4px #d7deef;

    h5 {
      font-size: 31px;
      color: $gray1;
      font-family: "f4";
    }
  }
  .box {
    margin-top: 10px;
    padding: 10px;
    width: 100%;
    p {
      font-size: 19px;
      color: $primary1;
      font-family: "f2";
      margin-bottom: 5px;
    }
    input {
      font-size: 18px;
      font-family: f2;
      width: 100%;
      height: 48px;
      background: #f3f7ff;
      border-radius: 10px;
      border: 0;
      box-shadow:
        inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      -webkit-box-shadow:
        inset 7px 7px 6px 0px #d6e0f5,
        inset -5px -5px 9px 1px #fff;
      padding: 0 20px;
    }
  }
}

.sign-up {
  padding: 30px 0;
  .login-btn {
    height: 50px;
    margin-top: 0px;
    font-size: 18px;
    font-family: "f3";
  }
  padding: 30px;
  .left-auth {
    width: 40%;
    display: flex;
    align-items: flex-end;
    img {
      width: 80%;
    }
  }
  .log-logo {
    position: absolute;
    left: 82px;
    top: 40px;
  }
  .bg1 {
    width: 190px;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 10%;
  }
  .bg2 {
    width: 230px;
    position: fixed;
    z-index: -1;
    transform: rotateY(180deg);
    bottom: -100px;
    right: 0%;
  }
}
// Passwod end

// Collection 1 Start
.rit-send-merch {
  position: relative;
  margin-bottom: 30px;
  z-index: -1 !important;
  h4 {
    color: $gray1;
    font-family: "f4";
  }
  .back-img {
    width: 94%;
  }
  .content-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #f4f8ff;
    box-shadow:
      inset -4px 4px 0px 2px #fff,
      inset -4px 4px 30px -8px #ffffff,
      -10px 10px 15px 2px rgb(161 183 219 / 44%);
    -webkit-box-shadow:
      inset -4px 4px 0px 2px #fff,
      inset -4px 4px 30px -8px #ffffff,
      -10px 10px 15px 2px rgb(161 183 219 / 44%);
    border-radius: 20px;
    z-index: -1;
    h5 {
      color: $gray1;
      font-family: "f4";
      margin-top: 0px;
      margin-bottom: 10px;
      font-size: 17px;
      width: 70%;
    }
    img {
      width: 70px;
      margin-bottom: 20px;
    }
    p {
      width: 96%;
      font-family: "f2";
      color: $primary1;
      font-size: 15px;
    }
  }
}

.box-merch {
  margin: 10px auto 50px;
}
// Collection 1 end

// Collection 4 start
.let-start-box {
  z-index: -1 !important;
  img {
    width: 100px;
  }
}

.let-start-box {
  box-shadow:
    inset 5px 7px 10px 5px #fff,
    inset 0px 3px 11px 7px #d8d8e7,
    5px 5px 10px 0px #eef1fd;
  -webkit-box-shadow:
    inset 5px 7px 10px 5px #fff,
    inset 0px 3px 11px 7px #d8d8e7,
    5px 5px 10px 0px #eef1fd;
  z-index: 3 !important;
}
// Collection 4 end

// Product Rdeem  start
.order-screen .active {
  width: 65px;
  height: 3px;
  background: $primary1;
}

.prod-list-login {
  .prod-list-box {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 11px 11px 29px -1px #bacff0;
    -webkit-box-shadow: 11px 11px 29px -1px #bacff0;
    .prod-list-box-img {
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .btm-prod {
      h3 {
        font-size: 17px;
        margin-top: 10px;
        color: #000;
        font-family: "f4";
      }
      p {
        font-size: 16px;
      }
      .plus {
        width: 35px;
        height: 35px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin: auto 0 auto auto;
        color: #fff;
        font-size: 33px;
        font-family: "f5";
        background: #07679c;
      }
    }
  }
}

// Product Rdeem  end

// Redeem start
.redeem-right {
  height: 100vh;
  background: rgb(226, 236, 255);
  overflow: hidden;
}

.left-form {
  height: calc(100vh - 80px) !important;
}

.box1 {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  select {
    font-size: 18px !important;
    font-family: f2;
    width: 100%;
    // color: $primary1;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
    &::placeholder {
      color: #d1def9;
    }
  }
}
.box6 {
  width: 100%;
  // margin-top: 10px;
  padding: 10px;
  select {
    font-size: 18px !important;
    font-family: f2;
    width: 100%;
    // color: $primary1;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 55px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
    &::placeholder {
      color: #d1def9;
    }
  }
}

.box5 {
  width: 100%;
  margin-top: 10px;
  // padding: 10px;
  select {
    font-size: 18px !important;
    font-family: f2;
    width: 100%;
    // color: $primary1;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
  }
  input {
    font-size: 18px;
    font-family: f2;
    width: 100%;
    height: 48px;
    background: #f3f7ff;
    border-radius: 10px;
    border: 0;
    box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    -webkit-box-shadow:
      inset 7px 7px 6px 0px #d6e0f5,
      inset -5px -5px 9px 1px #fff;
    padding: 0 20px;
    &::placeholder {
      color: #d1def9;
    }
  }
}

.box-custom {
  font-size: 18px;
  font-family: f2;
  // width: 100%;
  height: 48px;
  background: #f3f7ff;
  border-radius: 10px;
  border: 0;
  box-shadow:
    inset 7px 7px 6px 0px #d6e0f5,
    inset -5px -5px 9px 1px #fff;
  -webkit-box-shadow:
    inset 7px 7px 6px 0px #d6e0f5,
    inset -5px -5px 9px 1px #fff;
  input {
    &::placeholder {
      color: #d1def9;
    }
  }
}

.tickBoxDesign1 {
  height: 17px !important;
  width: 17px !important;
  padding: 1px;
  color: white;
  background-color: $primary;
}

.tickBoxDesign4 {
  height: 17px !important;
  width: 17px !important;
  background-color: transparent !important;
  border: $primary 2px solid !important;
  color: white;
}

// Redeem end

// Sellect shipping start
// Theem Select Screen start
.theem_select {
  height: 66px;
  width: 66px;
}
.font-align {
  left: 514px;
  bottom: -37px;
}
// Theem Select Screen end

.form-box-shadow {
  box-shadow:
    inset -9px -9px 8px 0px rgba(159, 200, 233, 0.31),
    inset 3px 5px 6px 3px rgba(255, 255, 255, 0.45),
    4px 3px 11px 5px rgba(179, 209, 240, 0.2);
}

.custom-img-form2 {
  height: 100px !important;
  width: 100px !important;
  object-fit: contain;
  padding: 10px;
}
.form_cont3 {
  background: #fff;
  // height: 327px !important;
  // height: 60% !important;
  width: 486px;
  border-radius: 25px;
  padding: 15px;
}
// Sellect shipping end

// Confirmation Popup start
.modal-popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 111;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .back-close {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
  }
  .center-content {
    background: #f4f8ff;
    border-radius: 30px;
    position: fixed;
    top: 5vh;
    width: 86%;
    height: 87vh;
    overflow: scroll;
  }
  .left-popup1 {
    height: 100%;
    background: #e7eefa;
    align-items: center;
    justify-content: end;
    display: flex;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    .imges {
      width: 100%;
      height: 80%;
      background: #f4f8ff;
      box-shadow: 0px 15px 10px -8px #d5e5fb;
      -webkit-box-shadow: 0px 15px 10px -8px #d5e5fb;
      border-radius: 30px;
      display: flex;
      align-items: center;
      margin-right: -50px;
      justify-content: center;
      overflow: hidden;
      img {
        width: 80%;
        margin: auto;
      }
    }
    .owl-dots {
      margin: -10px auto 20px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      justify-content: center;
      .owl-dot {
        width: 30px;
        height: 7px;
        border-radius: 10px;
        margin: 0 4px;
        background: $lt-blue1;
        transition: 0.5s all linear;
      }
      .active {
        background: $primary;
        width: 45px;
        transition: 0.5s all linear;
      }
    }
  }
  .left-popup {
    z-index: 1;
    width: 450px;
    height: 100%;
    background: #e7eefa;
    align-items: center;
    justify-content: end;
    display: flex;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    .imges {
      width: 460px;
      height: 80%;
      background: #f4f8ff;
      box-shadow: 0px 20px 20px -8px #d5e5fb;
      -webkit-box-shadow: 0px 30px 20px -8px #d5e5fb;
      border-radius: 30px;
      display: flex;
      align-items: center;
      margin-right: -50px;
      justify-content: center;
      img {
        width: 80%;
        margin: auto;
      }
    }
    .owl-dots {
      margin: -10px auto 20px;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      justify-content: center;
      .owl-dot {
        width: 30px;
        height: 7px;
        border-radius: 10px;
        margin: 0 4px;
        background: $lt-blue1;
        transition: 0.5s all linear;
      }
      .active {
        background: $primary;
        width: 45px;
        transition: 0.5s all linear;
      }
    }
  }
  .rit-pop {
    padding-left: 80px;
    background: #f4f8ff;
    height: 100%;
    h2 {
      color: #000;
      font-family: "f4";
      margin-top: 30px;
      span {
        color: $primary1;
        font-family: "f2";
        font-size: 15px;
        margin-left: 10px;
      }
    }
    p {
      margin-bottom: 6px;
    }
    h3 {
      font-size: 20px;
      color: #000;
      font-family: "f4";
      margin-bottom: 15px;
      margin-top: 20px;
    }
    .color-box {
      width: 22px;
      height: 22px;
      border-radius: 4px;
      margin-right: 10px;
    }
    .qunt-btn {
      width: 40px;
      height: 40px;
      font-size: 20px;
      font-family: "f1";
      border-radius: 100px;
      line-height: 4px;
      box-shadow:
        inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
        inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
        4px 5px 6px 1px rgba(149, 192, 234, 0.4);
      -webkit-box-shadow:
        inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
        inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
        4px 5px 6px 1px rgba(149, 192, 234, 0.4);
      background: #ffffff;
    }
  }
  .modal-cls-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    font-size: 20px;
    font-family: "f1";
    border-radius: 100px;
    line-height: 4px;
    z-index: 111;
    box-shadow:
      inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
      inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
      4px 5px 6px 1px rgba(149, 192, 234, 0.4);
    -webkit-box-shadow:
      inset -10px -10px 8px -1px rgba(219, 234, 255, 0.61),
      inset 30px 30px 15px 5px rgba(255, 255, 255, 0.26),
      4px 5px 6px 1px rgba(149, 192, 234, 0.4);
    background: #ffffff;
    img {
      width: 55%;
    }
  }
}

.center-content3 {
  background: #f4f8ff;
  border-radius: 30px;
  position: fixed;
  // top: 5vh;
  width: 35%;
  height: 45vh;
  overflow: scroll;
}
// Confirmation Popup end

// Footer start
.footer-one-sec {
  padding: 20px 5px;
  background: $lt-blue;
  .footer-one-inner-cont {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    button {
      border: none;
      background-color: transparent;
      padding: 5px 0;
      font-family: "f2";
      font-size: 15px;
      color: white;

      span {
        color: $primary;
      }
    }

    ul {
      width: 210px;
      margin: 15px auto;
      li {
        //   // width: 65px;
        //   // margin: 5px 0;
        //   // font-family: "f1";
        //   // font-size: 12px;
        //   // color: white;
      }
      .footer-btns {
        color: #fff;
      }
      .footer-btns:hover {
        color: $primary1;
      }
      margin-bottom: 0;
    }
    p {
      width: 210px;
      margin: 5px auto;
    }
  }
}
// Footer end

// Table Border start
.borer-r {
  border: 2px solid #e2ecff !important;
  // border-bottom-left-radius: 25px !important;
}
// Table Border end

.active {
  width: 60px;
  height: 3px;
  background: $primary1;
  position: absolute;
  bottom: -5px;
  left: calc(50% - 30px);
  border-radius: 10px;
}
// Submenu Start
// .sub .drop-menu .submenu {
// position: absolute;
// width: 200px;
//   width: 100%;
//   background-color: red;
//   box-shadow: 0 20px 45px #00000020;
//   margin-top: -500px;
//   opacity: 0;
//   z-index: 99;
//   transition: all ease 0.3s;
//   text-align: center;
// }
// .sub .drop-menu:hover .submenu {
//   z-index: 99;
//   opacity: 1;
//   margin-top: 16px;
// }
button h5:hover {
  text-decoration: underline;
}

.sign-btn {
  width: 125px;
  height: 50px;
  border-radius: 13px;
  align-items: center;
  background: $lt-blue1;
  justify-content: space-between;
  display: flex;
  padding: 0 30px;
  svg {
    width: 32px;
    height: 32px;
  }
  .user-icon {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
}

//

.sign-btn3 {
  width: 50px;
  height: 50px;
  border-radius: 13px;
  align-items: center;
  background: #cde2fe;
  justify-content: center;
  display: flex;
  padding: 0 30px;
}
// Menu Close Style start
.invisible-cont {
  position: fixed;
  left: 0;
  top: 125px;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: red;
}
.invisible-cont2 {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: red;
}
.cust-ind {
  z-index: -1 !important;
}
// Menu Close Style end

// Submenu End

// Border
.border-cust {
  border-bottom: 2px solid $primary !important;
}
// Border
