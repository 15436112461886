.b1 {
  border: 1px;
}
.b2 {
  border: 2px;
}
.b3 {
  border: 3px;
}
.b4 {
  border: 4px;
}
.b5 {
  border: 5px;
}
.b6 {
  border: 6px;
}
.b7 {
  border: 7px;
}
.b8 {
  border: 8px;
}
.b9 {
  border: 9px;
}
.b10 {
  border: 10px;
}
.br-1 {
  border-radius: 1px;
}
.br-2 {
  border-radius: 2px;
}
.br-3 {
  border-radius: 3px;
}
.br-4 {
  border-radius: 4px;
}
.br-5 {
  border-radius: 5px;
}
.br-6 {
  border-radius: 6px;
}
.br-7 {
  border-radius: 7px;
}
.br-8 {
  border-radius: 8px;
}
.br-9 {
  border-radius: 9px;
}
.br-10 {
  border-radius: 10px;
}
.br-15 {
  border-radius: 15px;
}
.br-20 {
  border-radius: 20px;
}
.br-25 {
  border-radius: 25%;
}
.br-50 {
  border-radius: 50%;
}
.br-75 {
  border-radius: 75%;
}
.br-100 {
  border-radius: 100%;
}

@media (max-width: 576px) {
  .b1-xs {
    border: 1px;
  }
  .b2-xs {
    border: 2px;
  }
  .b3-xs {
    border: 3px;
  }
  .b4-xs {
    border: 4px;
  }
  .b5-xs {
    border: 5px;
  }
  .b6-xs {
    border: 6px;
  }
  .b7-xs {
    border: 7px;
  }
  .b8-xs {
    border: 8px;
  }
  .b9-xs {
    border: 9px;
  }
  .b10-xs {
    border: 10px;
  }
  .br-1-xs {
    border-radius: 1px;
  }
  .br-2-xs {
    border-radius: 2px;
  }
  .br-3-xs {
    border-radius: 3px;
  }
  .br-4-xs {
    border-radius: 4px;
  }
  .br-5-xs {
    border-radius: 5px;
  }
  .br-6-xs {
    border-radius: 6px;
  }
  .br-7-xs {
    border-radius: 7px;
  }
  .br-8-xs {
    border-radius: 8px;
  }
  .br-9-xs {
    border-radius: 9px;
  }
  .br-10-xs {
    border-radius: 10px;
  }
  .br-15-xs {
    border-radius: 15px;
  }
  .br-20-xs {
    border-radius: 20px;
  }
  .br-25-xs {
    border-radius: 25%;
  }
  .br-50-xs {
    border-radius: 50%;
  }
  .br-75-xs {
    border-radius: 75%;
  }
  .br-100-xs {
    border-radius: 100%;
  }
}
@media (max-width: 577px) {
  .b1-sm {
    border: 1px;
  }
  .b2-sm {
    border: 2px;
  }
  .b3-sm {
    border: 3px;
  }
  .b4-sm {
    border: 4px;
  }
  .b5-sm {
    border: 5px;
  }
  .b6-sm {
    border: 6px;
  }
  .b7-sm {
    border: 7px;
  }
  .b8-sm {
    border: 8px;
  }
  .b9-sm {
    border: 9px;
  }
  .b10-sm {
    border: 10px;
  }
  .br-1-sm {
    border-radius: 1px;
  }
  .br-2-sm {
    border-radius: 2px;
  }
  .br-3-sm {
    border-radius: 3px;
  }
  .br-4-sm {
    border-radius: 4px;
  }
  .br-5-sm {
    border-radius: 5px;
  }
  .br-6-sm {
    border-radius: 6px;
  }
  .br-7-sm {
    border-radius: 7px;
  }
  .br-8-sm {
    border-radius: 8px;
  }
  .br-9-sm {
    border-radius: 9px;
  }
  .br-10-sm {
    border-radius: 10px;
  }
  .br-15-sm {
    border-radius: 15px;
  }
  .br-20-sm {
    border-radius: 20px;
  }
  .br-25-sm {
    border-radius: 25%;
  }
  .br-50-sm {
    border-radius: 50%;
  }
  .br-75-sm {
    border-radius: 75%;
  }
  .br-100-sm {
    border-radius: 100%;
  }
}
@media (max-width: 768px) {
  .b1-md {
    border: 1px;
  }
  .b2-md {
    border: 2px;
  }
  .b3-md {
    border: 3px;
  }
  .b4-md {
    border: 4px;
  }
  .b5-md {
    border: 5px;
  }
  .b6-md {
    border: 6px;
  }
  .b7-md {
    border: 7px;
  }
  .b8-md {
    border: 8px;
  }
  .b9-md {
    border: 9px;
  }
  .b10-md {
    border: 10px;
  }
  .br-1-md {
    border-radius: 1px;
  }
  .br-2-md {
    border-radius: 2px;
  }
  .br-3-md {
    border-radius: 3px;
  }
  .br-4-md {
    border-radius: 4px;
  }
  .br-5-md {
    border-radius: 5px;
  }
  .br-6-md {
    border-radius: 6px;
  }
  .br-7-md {
    border-radius: 7px;
  }
  .br-8-md {
    border-radius: 8px;
  }
  .br-9-md {
    border-radius: 9px;
  }
  .br-10-md {
    border-radius: 10px;
  }
  .br-15-md {
    border-radius: 15px;
  }
  .br-20-md {
    border-radius: 20px;
  }
  .br-25-md {
    border-radius: 25%;
  }
  .br-50-md {
    border-radius: 50%;
  }
  .br-75-md {
    border-radius: 75%;
  }
  .br-100-md {
    border-radius: 100%;
  }
}
@media (max-width: 992px) {
  .b1-lg {
    border: 1px;
  }
  .b2-lg {
    border: 2px;
  }
  .b3-lg {
    border: 3px;
  }
  .b4-lg {
    border: 4px;
  }
  .b5-lg {
    border: 5px;
  }
  .b6-lg {
    border: 6px;
  }
  .b7-lg {
    border: 7px;
  }
  .b8-lg {
    border: 8px;
  }
  .b9-lg {
    border: 9px;
  }
  .b10-lg {
    border: 10px;
  }
  .br-1-lg {
    border-radius: 1px;
  }
  .br-2-lg {
    border-radius: 2px;
  }
  .br-3-lg {
    border-radius: 3px;
  }
  .br-4-lg {
    border-radius: 4px;
  }
  .br-5-lg {
    border-radius: 5px;
  }
  .br-6-lg {
    border-radius: 6px;
  }
  .br-7-lg {
    border-radius: 7px;
  }
  .br-8-lg {
    border-radius: 8px;
  }
  .br-9-lg {
    border-radius: 9px;
  }
  .br-10-lg {
    border-radius: 10px;
  }
  .br-15-lg {
    border-radius: 15px;
  }
  .br-20-lg {
    border-radius: 20px;
  }
  .br-25-lg {
    border-radius: 25%;
  }
  .br-50-lg {
    border-radius: 50%;
  }
  .br-75-lg {
    border-radius: 75%;
  }
  .br-100-lg {
    border-radius: 100%;
  }
}
@media (max-width: 1200px) {
  .b1-xl {
    border: 1px;
  }
  .b2-xl {
    border: 2px;
  }
  .b3-xl {
    border: 3px;
  }
  .b4-xl {
    border: 4px;
  }
  .b5-xl {
    border: 5px;
  }
  .b6-xl {
    border: 6px;
  }
  .b7-xl {
    border: 7px;
  }
  .b8-xl {
    border: 8px;
  }
  .b9-xl {
    border: 9px;
  }
  .b10-xl {
    border: 10px;
  }
  .br-1-xl {
    border-radius: 1px;
  }
  .br-2-xl {
    border-radius: 2px;
  }
  .br-3-xl {
    border-radius: 3px;
  }
  .br-4-xl {
    border-radius: 4px;
  }
  .br-5-xl {
    border-radius: 5px;
  }
  .br-6-xl {
    border-radius: 6px;
  }
  .br-7-xl {
    border-radius: 7px;
  }
  .br-8-xl {
    border-radius: 8px;
  }
  .br-9-xl {
    border-radius: 9px;
  }
  .br-10-xl {
    border-radius: 10px;
  }
  .br-15-xl {
    border-radius: 15px;
  }
  .br-20-xl {
    border-radius: 20px;
  }
  .br-25-xl {
    border-radius: 25%;
  }
  .br-50-xl {
    border-radius: 50%;
  }
  .br-75-xl {
    border-radius: 75%;
  }
  .br-100-xl {
    border-radius: 100%;
  }
}
@media (max-width: 1400px) {
  .b1-xxl {
    border: 1px;
  }
  .b2-xxl {
    border: 2px;
  }
  .b3-xxl {
    border: 3px;
  }
  .b4-xxl {
    border: 4px;
  }
  .b5-xxl {
    border: 5px;
  }
  .b6-xxl {
    border: 6px;
  }
  .b7-xxl {
    border: 7px;
  }
  .b8-xxl {
    border: 8px;
  }
  .b9-xxl {
    border: 9px;
  }
  .b10-xxl {
    border: 10px;
  }
  .br-1-xxl {
    border-radius: 1px;
  }
  .br-2-xxl {
    border-radius: 2px;
  }
  .br-3-xxl {
    border-radius: 3px;
  }
  .br-4-xxl {
    border-radius: 4px;
  }
  .br-5-xxl {
    border-radius: 5px;
  }
  .br-6-xxl {
    border-radius: 6px;
  }
  .br-7-xxl {
    border-radius: 7px;
  }
  .br-8-xxl {
    border-radius: 8px;
  }
  .br-9-xxl {
    border-radius: 9px;
  }
  .br-10-xxl {
    border-radius: 10px;
  }
  .br-15-xxl {
    border-radius: 15px;
  }
  .br-20-xxl {
    border-radius: 20px;
  }
  .br-25-xxl {
    border-radius: 25%;
  }
  .br-50-xxl {
    border-radius: 50%;
  }
  .br-75-xxl {
    border-radius: 75%;
  }
  .br-100-xxl {
    border-radius: 100%;
  }
}
