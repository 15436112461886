@import "../constatnt/fonts.scss";
@import "../constatnt/color.scss";

body,
html {
  margin: 0;
  padding: 0;
  font-family: "f1";
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "f3";
}

p {
  font-size: 14px;
}

h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  color: $black;
  display: inherit;
}
:focus-visible {
  outline: 0 !important;
}
::placeholder {
  font-size: 13px;
}
ul {
  list-style: none;
  padding: 0;
}
//
//
//
//
//
//

//height-start
.h-100 {
  height: 100vh !important;
}
.h-80 {
  height: 80vh !important;
}
.h-85 {
  height: 85vh !important;
}
.h-90 {
  height: 90vh !important;
}
.min-h-100 {
  min-height: 100vh !important;
}
//height-end
//
//
//
//flex-concept-start
.d-initial {
  display: initial;
}
.text-justify {
  text-align: justify;
}
.as-jc {
  align-items: start;
  justify-content: center;
}
.as-jb {
  align-items: start;
  justify-content: space-between;
}
.as-je {
  align-items: start;
  justify-content: flex-end;
}
.ac-jc {
  align-items: center;
  justify-content: center;
}
.ae-jc {
  align-items: end;
  justify-content: center;
}
.ae-jb {
  align-items: end;
  justify-content: space-between;
}
.ae-js {
  align-items: end;
  justify-content: flex-start;
}
.ae-je {
  align-items: end;
  justify-content: flex-end;
}
.ac-je {
  align-items: center;
  justify-content: flex-end;
}
.ac-js {
  align-items: center;
  justify-content: flex-start;
}
.ae-jb {
  align-items: end;
  justify-content: space-between;
}
.ac-jb {
  align-items: center;
  justify-content: space-between;
}
//flex-concept-end
//
//
//
.s-0 {
  left: 0 !important;
}
.s-3 {
  left: 3px !important;
}
.s-5 {
  left: 5px !important;
}
.s-8 {
  left: 8px !important;
}
.s-10 {
  left: 10px !important;
}
.s-13 {
  left: 13px !important;
}
.t-0 {
  top: 0 !important;
}
.t-5 {
  top: 5px !important;
}
.t-8 {
  top: 8px !important;
}
.visibility-hid {
  visibility: hidden !important;
}
.visibility-visible {
  visibility: visible !important;
}
.cust-btn {
  border: 0;
  background: transparent;
  padding: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 0px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
  // box-shadow: inset 0 0 10px $primary1;
}

::-webkit-scrollbar-thumb {
  // background: $primary1;
  box-shadow: inset 0 0 10px $primary1;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 10px $primary1;
  border-radius: 10px;
}
input:-internal-autofill-selected {
  color: transparent;
  background-color: -internal-light-dark(
    rgb(232, 240, 254),
    rgba(70, 90, 126, 0.4)
  ) !important;
  color: fieldtext !important;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
// .custom-file-input {
//   border: 1px dashed #c5c5c5;
//   padding: 6px;
//   width: 100%;
// }
.custom-file-input::before {
  content: "Upload Original Certificate";
  display: inline-block;
  background: #f1f7ff;
  border-radius: 3px;
  // padding: 7px 8px;
  outline: none;
  white-space: nowrap;
  color: #7a7a7a;
  font-size: 13px;
  font-family: "f1";
  width: 100%;
  text-align: left;
}
// .custom-file-input:hover::before {
//   border-color: black;
// }
// .custom-file-input:active::before {
//   background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
// }

.w-cust {
  width: 20% !important;
}
